<template>
  <v-card flat>
    <v-card-text>
      <v-container class="ma-10">
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title>Flight Equipment</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Aircraft" rules="required">
                      <v-select
                        v-model="mission_equipment.aircraft_id"
                        :items="dropdown.aircraft_types"
                        :error-messages="errors"
                        item-text="name"
                        item-value="id"
                        menu-props="auto"
                        label="Aircraft"
                        outlined
                        required
                        dense
                        hint="Select Aircraft"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Battery" rules="required">
                      <v-select
                        v-model="mission_equipment.battery_id"
                        :items="dropdown.batteries"
                        :error-messages="errors"
                        item-text="asset_number"
                        item-value="id"
                        menu-props="auto"
                        label="Battery"
                        outlined
                        required
                        dense
                        hint="Select Aircraft Battery"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Radio" rules="required">
                      <v-select
                        v-model="mission_equipment.radio_id"
                        :items="dropdown.radios"
                        :error-messages="errors"
                        item-text="asset_number"
                        item-value="id"
                        menu-props="auto"
                        label="Radio"
                        outlined
                        required
                        dense
                        hint="Select Radio"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Camera" rules="required">
                      <v-select
                        v-model="mission_equipment.camera_id"
                        :items="dropdown.cameras"
                        :error-messages="errors"
                        item-text="asset_number"
                        item-value="id"
                        menu-props="auto"
                        label="Camera"
                        outlined
                        required
                        dense
                        hint="Select Camera"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card outlined>
              <v-card-title>Field Equipment</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Fire Extinguisher" rules="required">
                      <v-text-field
                        v-model="mission_equipment.fire_extinguisher"
                        :error-messages="errors"
                        label="Fire Extinguisher"
                        required
                        outlined
                        dense
                        type="number"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="First Aid Kits" rules="required">
                      <v-text-field
                        v-model="mission_equipment.first_aid_kits"
                        :error-messages="errors"
                        label="First Aid Kits"
                        required
                        outlined
                        dense
                        type="number"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Safety Signs" rules="required">
                      <v-text-field
                        v-model="mission_equipment.safety_signs"
                        :error-messages="errors"
                        label="Safety Signs"
                        required
                        outlined
                        dense
                        type="number"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Reflective Jackets" rules="required">
                      <v-text-field
                        v-model="mission_equipment.reflective_jackets"
                        :error-messages="errors"
                        label="Reflective Jackets"
                        required
                        outlined
                        dense
                        type="number"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Cones" rules="required">
                      <v-text-field
                        v-model="mission_equipment.cones"
                        :error-messages="errors"
                        label="Cones"
                        required
                        outlined
                        dense
                        type="number"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Helipads" rules="required">
                      <v-text-field
                        v-model="mission_equipment.helipads"
                        :error-messages="errors"
                        label="Helipads"
                        required
                        outlined
                        dense
                        type="number"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="2 Way Radios" rules="required">
                      <v-text-field
                        v-model="mission_equipment.duplex_way_radios"
                        :error-messages="errors"
                        label="2 Way Radios"
                        required
                        outlined
                        dense
                        type="number"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Cordon Tape Rolls" rules="required">
                      <v-text-field
                        v-model="mission_equipment.cordon_tape_rolls"
                        :error-messages="errors"
                        label="Cordon Tape Rolls"
                        required
                        outlined
                        dense
                        type="number"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider } from "vee-validate";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "MissionEquipmentAdd",
  components: {
    ValidationProvider,
  },
  props: {
    formData: {
      type: [Array, Object],
      default() {
        return {
          mission_equipment: {},
        };
      },
    },
  },
  data() {
    return { mission_equipment: {} };
  },
  computed: {
    dropdown() {
      return this.$store.getters.dropdown;
    },
    equipment() {
      return this.formData.mission_equipment;
    },
  },
  watch: {
    equipment() {
      if (this.formData.mission_equipment) {
        this.mission_equipment = this.formData.mission_equipment;
      }
    },
  },
  methods: {
    updateFormData() {
      return { mission_equipment: this.mission_equipment };
    },
  },
};
</script>
