<template>
  <v-card flat>
    <v-card-text>
      <v-container class="ma-10">
        <v-row>
          <v-col cols="12">
            <v-card outlined class="">
              <v-card-title>Crew</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Pilot In Command" rules="required">
                      <v-select
                        v-model="crew.pilot_in_command"
                        :items="dropdown.users"
                        :error-messages="errors"
                        item-text="name"
                        item-value="id"
                        menu-props="auto"
                        label="Pilot In Command"
                        outlined
                        required
                        dense
                        hint="Select person"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Spotter" rules="">
                      <v-select
                        v-model="crew.spotter"
                        :items="dropdown.users"
                        :error-messages="errors"
                        item-text="name"
                        item-value="id"
                        menu-props="auto"
                        label="Spotter"
                        outlined
                        dense
                        hint="Select person (optional)"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Observer" rules="">
                      <v-select
                        v-model="crew.observer"
                        :items="dropdown.users"
                        :error-messages="errors"
                        item-text="name"
                        item-value="id"
                        menu-props="auto"
                        label="Observer"
                        outlined
                        dense
                        hint="Select person (optional)"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Security" rules="">
                      <v-select
                        v-model="crew.security"
                        :items="dropdown.users"
                        :error-messages="errors"
                        item-text="name"
                        item-value="id"
                        menu-props="auto"
                        label="Security"
                        outlined
                        dense
                        hint="Select person (optional)"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider } from "vee-validate";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "MissionCrewAdd",
  components: {
    ValidationProvider,
  },
  props: {
    formData: {
      type: [Array, Object],
      default() {
        return {
          mission_crews: {},
        };
      },
    },
  },
  data() {
    return { crew: {} };
  },
  computed: {
    dropdown() {
      return this.$store.getters.dropdown;
    },
    crewStump() {
      return this.formData.mission_crews;
    },
  },
  watch: {
    crewStump() {
      if (this.formData.mission_crews) {
        this.crew = this.formData.mission_crews;
      }
    },
  },
  methods: {
    updateFormData() {
      return { mission_crews: this.crew };
    },
  },
};
</script>
