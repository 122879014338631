var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',{staticClass:"ma-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Crew")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Pilot In Command","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dropdown.users,"error-messages":errors,"item-text":"name","item-value":"id","menu-props":"auto","label":"Pilot In Command","outlined":"","required":"","dense":"","hint":"Select person","persistent-hint":""},model:{value:(_vm.crew.pilot_in_command),callback:function ($$v) {_vm.$set(_vm.crew, "pilot_in_command", $$v)},expression:"crew.pilot_in_command"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Spotter","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dropdown.users,"error-messages":errors,"item-text":"name","item-value":"id","menu-props":"auto","label":"Spotter","outlined":"","dense":"","hint":"Select person (optional)","persistent-hint":""},model:{value:(_vm.crew.spotter),callback:function ($$v) {_vm.$set(_vm.crew, "spotter", $$v)},expression:"crew.spotter"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Observer","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dropdown.users,"error-messages":errors,"item-text":"name","item-value":"id","menu-props":"auto","label":"Observer","outlined":"","dense":"","hint":"Select person (optional)","persistent-hint":""},model:{value:(_vm.crew.observer),callback:function ($$v) {_vm.$set(_vm.crew, "observer", $$v)},expression:"crew.observer"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Security","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dropdown.users,"error-messages":errors,"item-text":"name","item-value":"id","menu-props":"auto","label":"Security","outlined":"","dense":"","hint":"Select person (optional)","persistent-hint":""},model:{value:(_vm.crew.security),callback:function ($$v) {_vm.$set(_vm.crew, "security", $$v)},expression:"crew.security"}})]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }