<template>
  <v-card flat>
    <v-card-text>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
        <v-card flat class="pa-15">
          <v-row>
            <v-col cols="12">
              <v-btn tile outlined color="primary" class="my-10 mx-5" @click="closeMaps()">
                Done
              </v-btn>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list three-line subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6">Information</v-list-item-title>

                    <p class="text-body-1 mt-5">
                      When you select a location for your mission, weather patterns and forecasts for the next 14 days
                      is generated. Based on weather patterns, in the location that you have selected an algorithm will
                      generate a report for your mission. Alerting you of the proper times to Fly. That way, you are
                      always ahead of the curve and you are in the know of when conditions change mid flight or mission.
                      <br /><br />
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="6">
              <v-card class="pa-10">
                <custom-google-maps
                  @setLocation="setLocation"
                  :latitude="location.lat"
                  :longitude="location.lng"
                  :full_address="location.address"
                ></custom-google-maps>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title>Co-ordinates</v-card-title>
            <v-card-text>
              <v-btn tile color="success" @click="showMaps()">
                <v-icon left>
                  {{ icon.mdiMapLegend }}
                </v-icon>
                Select Site Co-Ordinates On Map
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-list three-line subheader>
            <v-list-item>
              <v-list-item-content>
                <v-card v-if="weather.today" class="mx-right">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">
                        {{ weather.today.address }}
                      </v-list-item-title>
                      <v-list-item-subtitle> {{ today }} {{ time }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-text>
                    <v-row align="center">
                      <v-col class="text-h3" cols="6">
                        {{ weather.today.currentConditions.temp }}&deg;C
                        <span class="text-h6 text-center">{{ weather.today.currentConditions.conditions }}</span>
                      </v-col>
                      <v-col cols="6">
                        <v-img :src="getImgUrl" :alt="`${weather.today.currentConditions.icon}`" width="92"></v-img>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-list-item>
                    <v-list-item-subtitle>
                      <v-icon>{{ icon.mdiCoolantTemperature }}</v-icon>
                      Feels like: &emsp;{{ weather.today.currentConditions.feelslike }}&deg;C
                    </v-list-item-subtitle>

                    <v-list-item-subtitle>
                      <v-icon>{{ icon.mdiWeatherWindy }}</v-icon>
                      Wind: &emsp;{{ weather.today.currentConditions.windspeed }}&deg;km/h
                    </v-list-item-subtitle>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list class="transparent">
                    <v-subheader class="text-h6">Forecast</v-subheader>
                    <v-list-item-group v-model="activeForecast" color="primary">
                      <v-virtual-scroll height="300" :items="weather.today.days" :item-height="90">
                        <template v-slot:default="{ item }">
                          <v-list-item @click="flyForecast(item.hours)">
                            <v-list-item-title class="text-caption">{{
                              getFormatDate(item.datetime)
                            }}</v-list-item-title>

                            <v-list-item-icon>
                              <v-avatar>
                                <!-- getImageIcon() -->
                                <v-img
                                  :src="`@/assets/icons/${item.icon}.png`"
                                  :alt="`${item.icon}`"
                                  width="28"
                                ></v-img>
                              </v-avatar>
                            </v-list-item-icon>

                            <v-list-item-subtitle class="text-right"> {{ item.temp }}&deg;C </v-list-item-subtitle>
                          </v-list-item>
                        </template>
                      </v-virtual-scroll>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" sm="8">
          <v-card>
            <v-card-title>
              Conditions
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table :headers="headers" :items="flyStatus" :search="search">
              <template v-slot:item.visibility="{ item }">
                <v-chip :color="getColorVisibility(item.visibility)"> {{ item.visibility }} km </v-chip>
              </template>
              <template v-slot:item.uvindex="{ item }">
                <v-chip :color="getColorUvIndex(item.uvindex)">
                  {{ item.uvindex }}
                </v-chip>
              </template>
              <template v-slot:item.temp="{ item }">
                <v-chip :color="getColorTemp(item.temp)"> {{ item.temp }}°C </v-chip>
              </template>
              <template v-slot:item.windspeed="{ item }">
                <v-chip :color="getColorWind(item.windspeed)"> {{ kmph_to_mps(item.windspeed) }} ms </v-chip>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="getStatusColor(item.status)">
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMapLegend, mdiCoolantTemperature, mdiWeatherWindy } from "@mdi/js";
import moment from "moment";
import Request from "@/core/api/request";
import CustomGoogleMaps from "@/components/GoogleMaps.vue";
import util from "@/core/utils/misc";

export default {
  components: {
    CustomGoogleMaps,
  },
  data() {
    return {
      location: {},
      dialog: false,
      items: [],
      weather: {},
      activeForecast: 0,
      search: "",
      headers: [
        { text: "Time", value: "datetime" },
        { text: "Wind Speed", value: "windspeed" },
        { text: "Temp", value: "temp" },
        { text: "Dew", value: "dew" },
        { text: "Pressure", value: "pressure" },
        { text: "Precip", value: "precip" },
        { text: "Visibility", value: "visibility" },
        { text: "Kp", value: "uvindex" },
        { text: "Fly Status", value: "status" },
      ],
      flyStatus: [],
      icon: {
        mdiMapLegend,
        mdiCoolantTemperature,
        mdiWeatherWindy,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.userinfo;
    },
    getImgUrl() {
      // eslint-disable-next-line
      return require("@/assets/icons/" + this.weather.today.currentConditions.icon + ".png");
    },
    today() {
      return "Today: ";
    },
    time() {
      return moment().format("LTS");
    },
  },
  methods: {
    async Initialize(location) {
      const param = {
        address: location.full_address,
        latitude: location.latitude,
        longitude: location.longitude,
      };
      // eslint-disable-next-line
      await Request.query(`${this.$IYA.URI.WEATHER}`, param).then(response => {
        const { data } = response;
        this.weather = data;
        console.log(this.weather.today);
      });
      util.isLoading();
    },
    showMaps() {
      this.dialog = true;
    },
    closeMaps() {
      this.dialog = false;
    },
    setLocation(value) {
      util.isLoading({ active: true, title: "Wait, while we fetch location weather forecasts." });
      this.location.address = value.full_address;
      this.location.lat = value.latitude;
      this.location.lng = value.longitude;
      this.Initialize(value);
    },
    async Report() {
      this.weather.today.user_id = this.user.id;
      await Request.post(`${this.$IYA.URI.WEATHER_REPORT}`, this.weather.today).then(response => {
        console.log(response);

        const FILE = window.URL.createObjectURL(new Blob([response.data]));
        const docUrl = document.createElement("x");
        docUrl.href = FILE;
        docUrl.setAttribute("download", "Report.pdf");
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
    updateFormData() {
      return { map: this.weather.today };
    },
    getFormatDate(date) {
      // eslint-disable-next-line
      return moment(date).format("dddd") + " " + moment(date).format("MMM Do YY");
    },
    getImageIcon(icon) {
      // eslint-disable-next-line
      return require("@/assets/icons/" + icon + ".png");
    },
    flyForecast(item) {
      this.flyStatus = [];
      item.forEach(element => {
        const precipProb = !element.precipprob ? element.precip : element.precipprob;
        const statusValue = this.getStatus(element);
        this.flyStatus.push({
          datetime: element.datetime,
          windspeed: element.windspeed,
          temp: element.temp,
          dew: element.dew,
          pressure: element.pressure,
          // eslint-disable-next-line
          precip: precipProb + " %",
          uvindex: element.uvindex,
          visibility: element.visibility,
          status: statusValue,
        });
      });
    },
    getColorUvIndex(uvindex) {
      if (uvindex > 3) return "#FF8A80";
      if (uvindex < 0) return "#FF8A80";
      return "#B9F6CA";
    },

    getColorTemp(temp) {
      if (temp > 35) return "#FF8A80";
      if (temp < 9) return "#FF8A80";
      return "#B9F6CA";
    },

    getColorVisibility(visibility) {
      if (visibility < 5) return "#FF8A80";
      return "#B9F6CA";
    },

    getColorWind(kmph) {
      const speed = this.kmph_to_mps(kmph);
      if (speed < 10) return "#B9F6CA";
      return "#FF8A80";
    },

    getStatusColor(status) {
      if (status === "Bad") return "#FF8A80";
      return "#B9F6CA";
    },

    kmph_to_mps(kmph) {
      return parseFloat(0.277778 * kmph).toFixed(2);
    },

    getStatus(element) {
      if (this.kmph_to_mps(element.windspeed) > 10) return "Bad";
      if (element.temp > 35 || element.temp < 9) return "Bad";
      if (element.visibility < 5) return "Bad";
      if (element.uvindex < 0 || element.uvindex > 3) return "Bad";
      return "Good";
    },
  },
};
</script>
