var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',{staticClass:"ma-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Flight Equipment")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Aircraft","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dropdown.aircraft_types,"error-messages":errors,"item-text":"name","item-value":"id","menu-props":"auto","label":"Aircraft","outlined":"","required":"","dense":"","hint":"Select Aircraft","persistent-hint":""},model:{value:(_vm.mission_equipment.aircraft_id),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "aircraft_id", $$v)},expression:"mission_equipment.aircraft_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Battery","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dropdown.batteries,"error-messages":errors,"item-text":"asset_number","item-value":"id","menu-props":"auto","label":"Battery","outlined":"","required":"","dense":"","hint":"Select Aircraft Battery","persistent-hint":""},model:{value:(_vm.mission_equipment.battery_id),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "battery_id", $$v)},expression:"mission_equipment.battery_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Radio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dropdown.radios,"error-messages":errors,"item-text":"asset_number","item-value":"id","menu-props":"auto","label":"Radio","outlined":"","required":"","dense":"","hint":"Select Radio","persistent-hint":""},model:{value:(_vm.mission_equipment.radio_id),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "radio_id", $$v)},expression:"mission_equipment.radio_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Camera","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dropdown.cameras,"error-messages":errors,"item-text":"asset_number","item-value":"id","menu-props":"auto","label":"Camera","outlined":"","required":"","dense":"","hint":"Select Camera","persistent-hint":""},model:{value:(_vm.mission_equipment.camera_id),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "camera_id", $$v)},expression:"mission_equipment.camera_id"}})]}}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Field Equipment")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Fire Extinguisher","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Fire Extinguisher","required":"","outlined":"","dense":"","type":"number"},model:{value:(_vm.mission_equipment.fire_extinguisher),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "fire_extinguisher", $$v)},expression:"mission_equipment.fire_extinguisher"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"First Aid Kits","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"First Aid Kits","required":"","outlined":"","dense":"","type":"number"},model:{value:(_vm.mission_equipment.first_aid_kits),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "first_aid_kits", $$v)},expression:"mission_equipment.first_aid_kits"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Safety Signs","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Safety Signs","required":"","outlined":"","dense":"","type":"number"},model:{value:(_vm.mission_equipment.safety_signs),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "safety_signs", $$v)},expression:"mission_equipment.safety_signs"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Reflective Jackets","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Reflective Jackets","required":"","outlined":"","dense":"","type":"number"},model:{value:(_vm.mission_equipment.reflective_jackets),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "reflective_jackets", $$v)},expression:"mission_equipment.reflective_jackets"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Cones","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Cones","required":"","outlined":"","dense":"","type":"number"},model:{value:(_vm.mission_equipment.cones),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "cones", $$v)},expression:"mission_equipment.cones"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Helipads","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Helipads","required":"","outlined":"","dense":"","type":"number"},model:{value:(_vm.mission_equipment.helipads),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "helipads", $$v)},expression:"mission_equipment.helipads"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"2 Way Radios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"2 Way Radios","required":"","outlined":"","dense":"","type":"number"},model:{value:(_vm.mission_equipment.duplex_way_radios),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "duplex_way_radios", $$v)},expression:"mission_equipment.duplex_way_radios"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Cordon Tape Rolls","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Cordon Tape Rolls","required":"","outlined":"","dense":"","type":"number"},model:{value:(_vm.mission_equipment.cordon_tape_rolls),callback:function ($$v) {_vm.$set(_vm.mission_equipment, "cordon_tape_rolls", $$v)},expression:"mission_equipment.cordon_tape_rolls"}})]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }