<template>
  <el-upload
    class="upload-demo"
    :action="`#`"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
    :on-change="handleChange"
    :file-list="fileList"
    :style="`width:150px;`"
  >
    <a :href="`${media_file}`" target="_blank" class="text-decoration-none info--text">
      <v-card outlined class="pa-1" width="150px">
        <img v-if="filename2" :src="`${placeholder}`" width="140px" />
        <div>
          <span class="info--text">{{ filename2 }}</span>
        </div>
      </v-card>
    </a>
    <div class="my-3">
      <v-btn text color="info">
        Upload
        <v-icon right dark>
          {{ icons.mdiCloudUpload }}
        </v-icon>
      </v-btn>
    </div>
  </el-upload>
</template>

<script>
import { mdiCloudUpload } from "@mdi/js";
import UploadService from "@/core/services/UploadFilesService";

export default {
  props: {
    uri: {
      type: String,
      default: "upload uri",
    },
    media_file: {
      type: String,
      default: "",
    },
  },
  computed: {
    filename2() {
      if (!this.filename) {
        const myArray = this.media_file.split("/");
        return myArray[myArray.length - 1];
      }
      return this.filename;
    },
    placeholder() {
      return this.$PDFPLACEHLODER;
    },
  },
  watch: {
    media_file() {
      const myArray = this.media_file.split("/");
      this.filename = myArray[myArray.length - 1];
      this.fileList = [{ name: myArray[myArray.length - 1], url: this.media_file }];
    },
  },
  data() {
    return {
      fileList: [],
      filename: "",
      message: "",
      icons: {
        mdiCloudUpload,
      },
    };
  },
  methods: {
    handleAvatarSuccess(response, file) {
      console.log(file);
      this.$emit("getFileName", response.data.file_name);
    },
    beforeAvatarUpload(file) {
      UploadService.upload(file, this.uri)
        .then(response => {
          this.message = response.data.message;
          this.$emit("getFileName", response.data.data.file_name);
          this.filename = response.data.data.file_name;
        })
        .catch(err => {
          // eslint-disable-next-line
          this.message = "Could not upload the file! " + err;
        });
      return false;
    },
    handleChange(file, fileList) {
      console.log(file);
      this.fileList = fileList.slice(-3);
    },
  },
};
</script>

<style>
.el-upload__input {
  visibility: hidden;
}
</style>
