var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',{staticClass:"ma-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Take Off & Landing")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Take Off Landing Site","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Take Off Landing Site","required":"","outlined":"","dense":"","hint":"Describe Site","persistent-hint":""},model:{value:(_vm.site_infos.take_off_landing_site),callback:function ($$v) {_vm.$set(_vm.site_infos, "take_off_landing_site", $$v)},expression:"site_infos.take_off_landing_site"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Take Off Site For Flight Folio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Take Off Site For Flight Folio","required":"","outlined":"","dense":"","hint":"Name or short description","persistent-hint":""},model:{value:(_vm.site_infos.take_off_site_for_flight_folio),callback:function ($$v) {_vm.$set(_vm.site_infos, "take_off_site_for_flight_folio", $$v)},expression:"site_infos.take_off_site_for_flight_folio"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Landing Site For Flight Folio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Landing Site For Flight Folio","required":"","outlined":"","dense":"","hint":"Name or short description","persistent-hint":""},model:{value:(_vm.site_infos.landing_site_for_flight_folio),callback:function ($$v) {_vm.$set(_vm.site_infos, "landing_site_for_flight_folio", $$v)},expression:"site_infos.landing_site_for_flight_folio"}})]}}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Take Off & Landing")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Terrain","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Terrain","required":"","outlined":"","dense":"","hint":"Flat, Hilly, Urban, Woods, Water, Roads, etc","persistent-hint":""},model:{value:(_vm.site_infos.terrain),callback:function ($$v) {_vm.$set(_vm.site_infos, "terrain", $$v)},expression:"site_infos.terrain"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Hazards","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Hazards","required":"","outlined":"","dense":"","hint":"Trees, Power Lines, Buildings, etc","persistent-hint":""},model:{value:(_vm.site_infos.hazards),callback:function ($$v) {_vm.$set(_vm.site_infos, "hazards", $$v)},expression:"site_infos.hazards"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Restrictions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Restrictions","required":"","outlined":"","dense":"","hint":"Power Plants, Prisons, Military, etc","persistent-hint":""},model:{value:(_vm.site_infos.restrictions),callback:function ($$v) {_vm.$set(_vm.site_infos, "restrictions", $$v)},expression:"site_infos.restrictions"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Sensitivities","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Sensitivities","required":"","outlined":"","dense":"","hint":"Nature reserves, Recreational areas, etc","persistent-hint":""},model:{value:(_vm.site_infos.sensitivities),callback:function ($$v) {_vm.$set(_vm.site_infos, "sensitivities", $$v)},expression:"site_infos.sensitivities"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"People","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"People","required":"","outlined":"","dense":"","hint":"Concentrations, Local habitation, etc","persistent-hint":""},model:{value:(_vm.site_infos.people),callback:function ($$v) {_vm.$set(_vm.site_infos, "people", $$v)},expression:"site_infos.people"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Footpaths","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Footpaths","required":"","outlined":"","dense":"","hint":"Public footpaths, Bridleways, etc","persistent-hint":""},model:{value:(_vm.site_infos.footpaths),callback:function ($$v) {_vm.$set(_vm.site_infos, "footpaths", $$v)},expression:"site_infos.footpaths"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Livestocks","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Livestocks","required":"","outlined":"","dense":"","hint":"Farms, Wildlife, etc","persistent-hint":""},model:{value:(_vm.site_infos.livestocks),callback:function ($$v) {_vm.$set(_vm.site_infos, "livestocks", $$v)},expression:"site_infos.livestocks"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Access Points","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Access Points","required":"","outlined":"","dense":"","hint":"For vehicles and possible public incursions","persistent-hint":""},model:{value:(_vm.site_infos.access_points),callback:function ($$v) {_vm.$set(_vm.site_infos, "access_points", $$v)},expression:"site_infos.access_points"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Payload","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Payload","required":"","outlined":"","dense":"","hint":"Description or Information","persistent-hint":""},model:{value:(_vm.site_infos.payload),callback:function ($$v) {_vm.$set(_vm.site_infos, "payload", $$v)},expression:"site_infos.payload"}})]}}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Site Checks")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Inform Local Emergancy Service","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.site_info_options,"error-messages":errors,"menu-props":"auto","label":"Inform Local Emergancy Service","outlined":"","required":"","dense":"","hint":"Select Option","persistent-hint":""},model:{value:(_vm.site_infos.inform_local_emergancy_service),callback:function ($$v) {_vm.$set(_vm.site_infos, "inform_local_emergancy_service", $$v)},expression:"site_infos.inform_local_emergancy_service"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Permissions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.site_info_options,"error-messages":errors,"menu-props":"auto","label":"Permissions","outlined":"","required":"","dense":"","hint":"Select Option","persistent-hint":""},model:{value:(_vm.site_infos.permissions),callback:function ($$v) {_vm.$set(_vm.site_infos, "permissions", $$v)},expression:"site_infos.permissions"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Road Closures","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.site_info_options,"error-messages":errors,"menu-props":"auto","label":"Road Closures","outlined":"","required":"","dense":"","hint":"Select Option","persistent-hint":""},model:{value:(_vm.site_infos.road_closures),callback:function ($$v) {_vm.$set(_vm.site_infos, "road_closures", $$v)},expression:"site_infos.road_closures"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Cordon Off","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.site_info_options,"error-messages":errors,"menu-props":"auto","label":"Cordon Off","outlined":"","required":"","dense":"","hint":"Select Option","persistent-hint":""},model:{value:(_vm.site_infos.cordon_off),callback:function ($$v) {_vm.$set(_vm.site_infos, "cordon_off", $$v)},expression:"site_infos.cordon_off"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Walk Flight Paths","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.site_info_options,"error-messages":errors,"menu-props":"auto","label":"Walk Flight Paths","outlined":"","required":"","dense":"","hint":"Select Option","persistent-hint":""},model:{value:(_vm.site_infos.walk_flight_paths),callback:function ($$v) {_vm.$set(_vm.site_infos, "walk_flight_paths", $$v)},expression:"site_infos.walk_flight_paths"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Additional Observer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.site_info_options,"error-messages":errors,"menu-props":"auto","label":"Additional Observer","outlined":"","required":"","dense":"","hint":"Select Option","persistent-hint":""},model:{value:(_vm.site_infos.additional_observer),callback:function ($$v) {_vm.$set(_vm.site_infos, "additional_observer", $$v)},expression:"site_infos.additional_observer"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Additional Security","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.site_info_options,"error-messages":errors,"menu-props":"auto","label":"Additional Security","outlined":"","required":"","dense":"","hint":"Select Option","persistent-hint":""},model:{value:(_vm.site_infos.additional_security),callback:function ($$v) {_vm.$set(_vm.site_infos, "additional_security", $$v)},expression:"site_infos.additional_security"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Safety Office","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.site_info_options,"error-messages":errors,"menu-props":"auto","label":"Safety Office","outlined":"","required":"","dense":"","hint":"Select Option","persistent-hint":""},model:{value:(_vm.site_infos.safety_office),callback:function ($$v) {_vm.$set(_vm.site_infos, "safety_office", $$v)},expression:"site_infos.safety_office"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Spectrum Analysis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.site_info_options,"error-messages":errors,"menu-props":"auto","label":"Spectrum Analysis","outlined":"","required":"","dense":"","hint":"Select Option","persistent-hint":""},model:{value:(_vm.site_infos.spectrum_analysis),callback:function ($$v) {_vm.$set(_vm.site_infos, "spectrum_analysis", $$v)},expression:"site_infos.spectrum_analysis"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Other1","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Other (Specify)","outlined":"","dense":"","hint":"Optional other requirements","persistent-hint":""},model:{value:(_vm.site_infos.other1),callback:function ($$v) {_vm.$set(_vm.site_infos, "other1", $$v)},expression:"site_infos.other1"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Other2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Other (Specify)","outlined":"","dense":"","hint":"Optional other requirements","persistent-hint":""},model:{value:(_vm.site_infos.other2),callback:function ($$v) {_vm.$set(_vm.site_infos, "other2", $$v)},expression:"site_infos.other2"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Other3","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Other (Specify)","outlined":"","dense":"","hint":"Optional other requirements","persistent-hint":""},model:{value:(_vm.site_infos.other3),callback:function ($$v) {_vm.$set(_vm.site_infos, "other3", $$v)},expression:"site_infos.other3"}})]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }