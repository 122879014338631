<template>
  <div>
    <div :dark="themeColor" :class="`v-input v-text-field v-text-field--is-booted`">
      <div class="v-input__control">
        <div class="v-input__slot">
          <div class="v-text-field__slot">
            <label class="v-label" style="left: 0px; right: auto; position: absolute; top: -15px">City or Town</label>
            <GmapAutocomplete
              ref="gmapAutocomplete"
              @place_changed="setPlace"
              :options="{
                fields: ['geometry', 'formatted_address', 'address_components'],
              }"
              @blur="addMarker"
              @change="addMarker"
              required
            />
          </div>
        </div>
      </div>
    </div>

    <br />
    <GmapMap :center="center" :zoom="12" style="width: 100%; height: 400px">
      <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" @click="center = m.position" />
    </GmapMap>
  </div>
</template>

<script>
import util from "@/core/utils/misc";

export default {
  name: "GoogleMap",
  props: {
    latitude: {
      type: Number,
      default: 24.90539849556627,
    },
    longitude: {
      type: Number,
      default: -34.03634101087244,
    },
    full_address: {
      type: String,
      default: "Port Elizabeth",
    },
  },
  data() {
    return {
      center: { lat: -33.918861, lng: 18.4233 },
      currentPlace: null,
      markers: [],
    };
  },
  computed: {
    themeColor() {
      // eslint-disable-next-line
      return this.$vuetify.dark ? true : false;
    },
  },
  watch: {
    full_address() {
      this.initMarker();
    },
  },
  mounted() {
    this.initMarker();
    this.geolocate();
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    initMarker() {
      const marker = {
        lat: this.latitude,
        lng: this.longitude,
      };
      this.markers.push({ position: marker });
      this.center = marker;
      this.$refs.gmapAutocomplete.$refs.input.value = this.full_address;
    },
    addMarker() {
      this.initMarker();
      if (this.currentPlace) {
        util.isLoading({ active: true, title: "Wait, while we fetch location weather forecasts." });
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.$emit("setLocation", {
          latitude: marker.lat,
          longitude: marker.lng,
          full_address: this.currentPlace.formatted_address,
        });
        this.currentPlace = null;
      }
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
};
</script>
