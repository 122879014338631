<template>
  <v-card flat>
    <v-card-text>
      <div class="text--primary">
        <v-row>
          <v-col cols="12">
            <v-card outlined class="">
              <v-card-title>Mission File Uploads</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-card class="pa-5" max-width="250px">
                      <v-card-title class="text-overline">Site Map</v-card-title>
                      <v-card-text>
                        <validation-provider name="File">
                          <element-file-uploader
                            :uri="fileUri"
                            :media_file="`${media_path}${media[0].path}`"
                            ref="imageUploadComponentSiteMap"
                            @getFileName="getSiteMapFileName"
                          ></element-file-uploader>
                        </validation-provider>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-card class="pa-5" max-width="250px">
                      <v-card-title class="text-overline">Fly-Away Zone</v-card-title>
                      <v-card-text>
                        <validation-provider name="File">
                          <element-file-uploader
                            :uri="fileUri"
                            :media_file="`${media_path}${media[1].path}`"
                            ref="imageUploadComponentFlyAway"
                            @getFileName="getFlyAwayFileName"
                          ></element-file-uploader>
                        </validation-provider>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-card class="pa-5" max-width="250px">
                      <v-card-title class="text-overline">Land Permission</v-card-title>
                      <v-card-text>
                        <validation-provider name="File">
                          <element-file-uploader
                            :uri="fileUri"
                            :media_file="`${media_path}${media[2].path}`"
                            ref="imageUploadComponentLandOwner"
                            @getFileName="getLandPermissionFileName"
                          ></element-file-uploader>
                        </validation-provider>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-card class="pa-5" max-width="250px">
                      <v-card-title class="text-overline">Supplementary</v-card-title>
                      <v-card-text>
                        <validation-provider name="File">
                          <element-file-uploader
                            :uri="fileUri"
                            :media_file="`${media_path}${media[3].path}`"
                            ref="imageUploadComponentSupplementary"
                            @getFileName="getSupplementaryFileName"
                          ></element-file-uploader>
                        </validation-provider>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheckDecagramOutline, mdiCloseBox } from "@mdi/js";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider } from "vee-validate";
import ElementFileUploader from "@/components/ElementFileUploader.vue";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "ChartUploadAdd",
  components: {
    ValidationProvider,
    ElementFileUploader,
  },
  props: {
    media: {
      type: Array,
      default() {
        return [
          { name: "Site Map", path: "" },
          { name: "Fly-Away Zone", path: "" },
          { name: "Land Owner's Permission", path: "" },
          { name: "Supplementary", path: "" },
        ];
      },
    },
  },
  data() {
    return {
      icons: {
        mdiCheckDecagramOutline,
        mdiCloseBox,
      },
    };
  },
  computed: {
    fileUri() {
      return this.$store.getters.fileUri;
    },
    media_path() {
      return this.$MEDIA;
    },
  },
  methods: {
    getSiteMapFileName(value) {
      console.log(`File: ${value}`);
      this.$set(this.media, 0, { name: "Site Map", path: value });
    },
    getFlyAwayFileName(value) {
      console.log(`File: ${value}`);
      this.$set(this.media, 1, { name: "Fly-Away Zone", path: value });
    },
    getLandPermissionFileName(value) {
      console.log(`File: ${value}`);
      this.$set(this.media, 2, { name: "Land Owner's Permission", path: value });
    },
    getSupplementaryFileName(value) {
      console.log(`File: ${value}`);
      this.$set(this.media, 3, { name: "Supplementary", path: value });
    },
    updateFormData() {
      return { chart: this.media };
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
