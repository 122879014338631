var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',{staticClass:"ma-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dropdown.users,"error-messages":errors,"item-text":"name","item-value":"id","menu-props":"auto","label":"Dispatcher","outlined":"","required":"","dense":"","hint":"Select person","persistent-hint":""},model:{value:(_vm.tasks.user_id),callback:function ($$v) {_vm.$set(_vm.tasks, "user_id", $$v)},expression:"tasks.user_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dropdown.clients,"error-messages":errors,"item-text":"name","item-value":"id","menu-props":"auto","label":"Client","outlined":"","required":"","dense":"","hint":"Select client","persistent-hint":""},model:{value:(_vm.tasks.client_id),callback:function ($$v) {_vm.$set(_vm.tasks, "client_id", $$v)},expression:"tasks.client_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Job Information")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Type Of Operation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Type Of Operation","required":"","outlined":"","dense":"","hint":"Brief job description","persistent-hint":""},model:{value:(_vm.tasks.type_of_operation),callback:function ($$v) {_vm.$set(_vm.tasks, "type_of_operation", $$v)},expression:"tasks.type_of_operation"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Location Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Location Description","required":"","outlined":"","dense":"","hint":"Brief location description","persistent-hint":""},model:{value:(_vm.tasks.location_description),callback:function ($$v) {_vm.$set(_vm.tasks, "location_description", $$v)},expression:"tasks.location_description"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-dialog',{attrs:{"minimum_date":"1950-01-01","error-messages":errors,"label":"Start Date"},on:{"forceDateUpdate":_vm.forceDateUpdate},model:{value:(_vm.tasks.start_date),callback:function ($$v) {_vm.$set(_vm.tasks, "start_date", $$v)},expression:"tasks.start_date"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Estimated Flight Duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-time-picker',{attrs:{"required":"","label":"Estimated Flight Duration","error-messages":errors},on:{"forceTimeUpdate":_vm.forceTimeUpdate},model:{value:(_vm.tasks.estimated_flight_duration),callback:function ($$v) {_vm.$set(_vm.tasks, "estimated_flight_duration", $$v)},expression:"tasks.estimated_flight_duration"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Line Of Sight Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.lineOfSight,"error-messages":errors,"menu-props":"auto","label":"Line Of Sight Type","outlined":"","required":"","dense":"","hint":"Select LOS type","persistent-hint":""},model:{value:(_vm.tasks.line_of_sight_type),callback:function ($$v) {_vm.$set(_vm.tasks, "line_of_sight_type", $$v)},expression:"tasks.line_of_sight_type"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Client Requirements","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","auto-grow":"","label":"Client Requirements","rows":"2","row-height":"20","required":"","dense":"","error-messages":errors,"hint":"Additional information","persistent-hint":""},model:{value:(_vm.tasks.client_requirements),callback:function ($$v) {_vm.$set(_vm.tasks, "client_requirements", $$v)},expression:"tasks.client_requirements"}})]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }