<template>
  <v-card flat>
    <v-card-text>
      <v-container class="ma-10">
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title>Take Off & Landing</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Take Off Landing Site" rules="required">
                      <v-text-field
                        v-model="site_infos.take_off_landing_site"
                        :error-messages="errors"
                        label="Take Off Landing Site"
                        required
                        outlined
                        dense
                        hint="Describe Site"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Take Off Site For Flight Folio" rules="required">
                      <v-text-field
                        v-model="site_infos.take_off_site_for_flight_folio"
                        :error-messages="errors"
                        label="Take Off Site For Flight Folio"
                        required
                        outlined
                        dense
                        hint="Name or short description"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Landing Site For Flight Folio" rules="required">
                      <v-text-field
                        v-model="site_infos.landing_site_for_flight_folio"
                        :error-messages="errors"
                        label="Landing Site For Flight Folio"
                        required
                        outlined
                        dense
                        hint="Name or short description"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card outlined>
              <v-card-title>Take Off & Landing</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Terrain" rules="required">
                      <v-text-field
                        v-model="site_infos.terrain"
                        :error-messages="errors"
                        label="Terrain"
                        required
                        outlined
                        dense
                        hint="Flat, Hilly, Urban, Woods, Water, Roads, etc"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Hazards" rules="required">
                      <v-text-field
                        v-model="site_infos.hazards"
                        :error-messages="errors"
                        label="Hazards"
                        required
                        outlined
                        dense
                        hint="Trees, Power Lines, Buildings, etc"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Restrictions" rules="required">
                      <v-text-field
                        v-model="site_infos.restrictions"
                        :error-messages="errors"
                        label="Restrictions"
                        required
                        outlined
                        dense
                        hint="Power Plants, Prisons, Military, etc"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Sensitivities" rules="required">
                      <v-text-field
                        v-model="site_infos.sensitivities"
                        :error-messages="errors"
                        label="Sensitivities"
                        required
                        outlined
                        dense
                        hint="Nature reserves, Recreational areas, etc"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="People" rules="required">
                      <v-text-field
                        v-model="site_infos.people"
                        :error-messages="errors"
                        label="People"
                        required
                        outlined
                        dense
                        hint="Concentrations, Local habitation, etc"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Footpaths" rules="required">
                      <v-text-field
                        v-model="site_infos.footpaths"
                        :error-messages="errors"
                        label="Footpaths"
                        required
                        outlined
                        dense
                        hint="Public footpaths, Bridleways, etc"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Livestocks" rules="required">
                      <v-text-field
                        v-model="site_infos.livestocks"
                        :error-messages="errors"
                        label="Livestocks"
                        required
                        outlined
                        dense
                        hint="Farms, Wildlife, etc"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Access Points" rules="required">
                      <v-text-field
                        v-model="site_infos.access_points"
                        :error-messages="errors"
                        label="Access Points"
                        required
                        outlined
                        dense
                        hint="For vehicles and possible public incursions"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Payload" rules="required">
                      <v-text-field
                        v-model="site_infos.payload"
                        :error-messages="errors"
                        label="Payload"
                        required
                        outlined
                        dense
                        hint="Description or Information"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card outlined>
              <v-card-title>Site Checks</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Inform Local Emergancy Service" rules="required">
                      <v-select
                        v-model="site_infos.inform_local_emergancy_service"
                        :items="site_info_options"
                        :error-messages="errors"
                        menu-props="auto"
                        label="Inform Local Emergancy Service"
                        outlined
                        required
                        dense
                        hint="Select Option"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Permissions" rules="required">
                      <v-select
                        v-model="site_infos.permissions"
                        :items="site_info_options"
                        :error-messages="errors"
                        menu-props="auto"
                        label="Permissions"
                        outlined
                        required
                        dense
                        hint="Select Option"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Road Closures" rules="required">
                      <v-select
                        v-model="site_infos.road_closures"
                        :items="site_info_options"
                        :error-messages="errors"
                        menu-props="auto"
                        label="Road Closures"
                        outlined
                        required
                        dense
                        hint="Select Option"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Cordon Off" rules="required">
                      <v-select
                        v-model="site_infos.cordon_off"
                        :items="site_info_options"
                        :error-messages="errors"
                        menu-props="auto"
                        label="Cordon Off"
                        outlined
                        required
                        dense
                        hint="Select Option"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Walk Flight Paths" rules="required">
                      <v-select
                        v-model="site_infos.walk_flight_paths"
                        :items="site_info_options"
                        :error-messages="errors"
                        menu-props="auto"
                        label="Walk Flight Paths"
                        outlined
                        required
                        dense
                        hint="Select Option"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Additional Observer" rules="required">
                      <v-select
                        v-model="site_infos.additional_observer"
                        :items="site_info_options"
                        :error-messages="errors"
                        menu-props="auto"
                        label="Additional Observer"
                        outlined
                        required
                        dense
                        hint="Select Option"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Additional Security" rules="required">
                      <v-select
                        v-model="site_infos.additional_security"
                        :items="site_info_options"
                        :error-messages="errors"
                        menu-props="auto"
                        label="Additional Security"
                        outlined
                        required
                        dense
                        hint="Select Option"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Safety Office" rules="required">
                      <v-select
                        v-model="site_infos.safety_office"
                        :items="site_info_options"
                        :error-messages="errors"
                        menu-props="auto"
                        label="Safety Office"
                        outlined
                        required
                        dense
                        hint="Select Option"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Spectrum Analysis" rules="required">
                      <v-select
                        v-model="site_infos.spectrum_analysis"
                        :items="site_info_options"
                        :error-messages="errors"
                        menu-props="auto"
                        label="Spectrum Analysis"
                        outlined
                        required
                        dense
                        hint="Select Option"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Other1" rules="">
                      <v-text-field
                        v-model="site_infos.other1"
                        :error-messages="errors"
                        label="Other (Specify)"
                        outlined
                        dense
                        hint="Optional other requirements"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Other2" rules="">
                      <v-text-field
                        v-model="site_infos.other2"
                        :error-messages="errors"
                        label="Other (Specify)"
                        outlined
                        dense
                        hint="Optional other requirements"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Other3" rules="">
                      <v-text-field
                        v-model="site_infos.other3"
                        :error-messages="errors"
                        label="Other (Specify)"
                        outlined
                        dense
                        hint="Optional other requirements"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider } from "vee-validate";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "SiteInfoAdd",
  components: {
    ValidationProvider,
  },
  props: {
    site_infos: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return { site_info_options: ["Required", "Not Required"] };
  },
  methods: {
    updateFormData() {
      return { siteinfo: this.site_infos };
    },
  },
};
</script>
