import Request from "@/core/api/request";

// import IYA from '@/core/contract'

class UploadFilesService {
  /**
   *
   * @param {*} file The file being uploaded
   * @param {*} uriPath The api path to upload to
   * @param {*} onUploadProgress The upload progress
   * @returns
   */
  // eslint-disable-next-line
  upload(file, uriPath) {
    const formData = new FormData();
    formData.append("file", file);
    return Request.post(uriPath, formData);
  }

  /**
   *
   * @returns
   */
  // eslint-disable-next-line
  getFiles(uriPath) {
    return Request.get(uriPath);
  }
}
export default new UploadFilesService();
