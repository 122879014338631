<template>
  <v-card flat>
    <v-card-text>
      <div class="text--primary">
        <v-row>
          <v-col cols="12">
            <v-card outlined class="">
              <v-card-title>Air Traffic Control Planning</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Take Off Call" rules="required">
                      <v-textarea
                        v-model="air_traffic_controls.take_off_call"
                        filled
                        auto-grow
                        label="Take Off Call"
                        rows="2"
                        row-height="20"
                        required
                        dense
                        :error-messages="errors"
                        hint="Take Off Details"
                        persistent-hint
                      ></v-textarea>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Landing Call" rules="required">
                      <v-textarea
                        v-model="air_traffic_controls.landing_call"
                        filled
                        auto-grow
                        label="Landing Call"
                        rows="2"
                        row-height="20"
                        required
                        dense
                        :error-messages="errors"
                        hint="Landing Details"
                        persistent-hint
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card outlined class="">
              <v-card-title>FPL Information</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <validation-provider v-slot="{ errors }" name="Flight Plan Required" rules="">
                      <v-checkbox
                        v-model="air_traffic_controls.flight_plan_required"
                        :error-messages="errors"
                        hide-details
                        label="Flight Plan Required"
                        class="shrink mr-2 mt-0"
                      ></v-checkbox>
                    </validation-provider>
                  </v-col>

                  <v-col cols="4">
                    <validation-provider v-slot="{ errors }" name="Camu Reference" rules="required">
                      <v-text-field
                        v-model="air_traffic_controls.camu_reference"
                        :error-messages="errors"
                        label="Camu Reference"
                        required
                        outlined
                        dense
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="4">
                    <validation-provider v-slot="{ errors }" name="Flight Plan Reference" rules="">
                      <v-text-field
                        v-model="air_traffic_controls.flight_plan_reference"
                        :error-messages="errors"
                        label="Flight Plan Reference"
                        outlined
                        dense
                        hint="Optional"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Ssr" rules="">
                      <v-text-field
                        v-model="air_traffic_controls.ssr"
                        :error-messages="errors"
                        label="SSR"
                        outlined
                        dense
                        hint="Optional: eg Fixed code 1234"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Field 18 Vfr" rules="">
                      <v-text-field
                        v-model="air_traffic_controls.field_18_vfr"
                        :error-messages="errors"
                        label="Field 18 VFR"
                        outlined
                        dense
                        hint="Optional: eg 150FT AGL"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Field 18 Rmk" rules="">
                      <v-text-field
                        v-model="air_traffic_controls.field_18_rmk"
                        :error-messages="errors"
                        label="Field 18 RMK"
                        outlined
                        dense
                        hint="Optional: eg Survey Flight With Geo Fencing"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider } from "vee-validate";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "AirTrafficControlAdd",
  components: {
    ValidationProvider,
  },
  props: {
    formData: {
      type: [Array, Object],
      default() {
        return {
          air_traffic_controls: {},
        };
      },
    },
  },
  watch: {
    formData() {
      console.log(this.formData.atc);
      if (this.formData.atc) {
        this.air_traffic_controls = this.formData.atc;
      }
    },
  },
  data() {
    return { air_traffic_controls: {} };
  },
  methods: {
    updateFormData() {
      return { air_traffic_controls: this.air_traffic_controls };
    },
  },
};
</script>
