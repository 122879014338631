var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"text--primary"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Air Traffic Control Planning")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Take Off Call","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","auto-grow":"","label":"Take Off Call","rows":"2","row-height":"20","required":"","dense":"","error-messages":errors,"hint":"Take Off Details","persistent-hint":""},model:{value:(_vm.atc.take_off_call),callback:function ($$v) {_vm.$set(_vm.atc, "take_off_call", $$v)},expression:"atc.take_off_call"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Landing Call","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","auto-grow":"","label":"Landing Call","rows":"2","row-height":"20","required":"","dense":"","error-messages":errors,"hint":"Landing Details","persistent-hint":""},model:{value:(_vm.atc.landing_call),callback:function ($$v) {_vm.$set(_vm.atc, "landing_call", $$v)},expression:"atc.landing_call"}})]}}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("FPL Information")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Flight Plan Required","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"error-messages":errors,"hide-details":"","label":"Flight Plan Required"},model:{value:(_vm.atc.flight_plan_required),callback:function ($$v) {_vm.$set(_vm.atc, "flight_plan_required", $$v)},expression:"atc.flight_plan_required"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Camu Reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Camu Reference","required":"","outlined":"","dense":""},model:{value:(_vm.atc.camu_reference),callback:function ($$v) {_vm.$set(_vm.atc, "camu_reference", $$v)},expression:"atc.camu_reference"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Flight Plan Reference","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Flight Plan Reference","outlined":"","dense":"","hint":"Optional","persistent-hint":""},model:{value:(_vm.atc.flight_plan_reference),callback:function ($$v) {_vm.$set(_vm.atc, "flight_plan_reference", $$v)},expression:"atc.flight_plan_reference"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Ssr","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"SSR","outlined":"","dense":"","hint":"Optional: eg Fixed code 1234","persistent-hint":""},model:{value:(_vm.atc.ssr),callback:function ($$v) {_vm.$set(_vm.atc, "ssr", $$v)},expression:"atc.ssr"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Field 18 Vfr","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Field 18 VFR","outlined":"","dense":"","hint":"Optional: eg 150FT AGL","persistent-hint":""},model:{value:(_vm.atc.field_18_vfr),callback:function ($$v) {_vm.$set(_vm.atc, "field_18_vfr", $$v)},expression:"atc.field_18_vfr"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Field 18 Rmk","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Field 18 RMK","outlined":"","dense":"","hint":"Optional: eg Survey Flight With Geo Fencing","persistent-hint":""},model:{value:(_vm.atc.field_18_rmk),callback:function ($$v) {_vm.$set(_vm.atc, "field_18_rmk", $$v)},expression:"atc.field_18_rmk"}})]}}])})],1)],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }