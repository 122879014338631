<template>
  <v-card flat>
    <v-card-title>
      {{ "Missions" }}
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" outlined color="indigo" @click="add()">
        {{ "Add New" }}
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="browse"
      :search="search"
      :loading="loading"
      loading-text="LOADING.... PLEASE WAIT"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.index="{ item }">
        {{ item.index }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.task="{ item }">
        {{ item.task.start_date }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.los="{ item }">
        {{ item.task.line_of_sight_type }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.lon="{ item }">
        {{ item.weather.longitude }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.lat="{ item }">
        {{ item.weather.latitude }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <span>
          <v-btn depressed text color="primary" @click="read(item.id)">
            <v-icon left dark>
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn depressed text color="error" @click="trash(item.id)">
            <v-icon left dark>
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
          <a :href="`${DOMAIN}mission/report/${item.id}`" target="_blank">
            <v-btn depressed text color="info">
              <v-icon left dark>
                {{ icons.mdiExport }}
              </v-icon>
            </v-btn>
          </a>
        </span>
      </template>
    </v-data-table>

    <v-row justify="center">
      <v-dialog v-model="dialogAdd" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card flat>
          <validation-observer ref="observer">
            <form id="submit_mission_form" class="" @submit.prevent="submit">
              <v-toolbar flat :style="`border-radius: 0px;`">
                <v-btn icon @click="dialogAdd = false">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
                <v-toolbar-title>New Mission</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text type="submit">
                  Save
                </v-btn>
              </v-toolbar>
              <v-divider></v-divider>
              <v-tabs>
                <v-tab>Task</v-tab>
                <v-tab>Crew</v-tab>
                <v-tab>Equipment</v-tab>
                <v-tab>Site Information</v-tab>
                <v-tab>Maps</v-tab>
                <v-tab>ATC</v-tab>
                <!-- <v-tab>Risk Management</v-tab>
            <v-tab>Emergency</v-tab> -->
                <v-tab>Charts & Uploads</v-tab>

                <v-tab-item>
                  <task ref="taskForm" @updateFormData="updateFormData"></task>
                </v-tab-item>
                <v-tab-item>
                  <crew ref="crewForm" @updateFormData="updateFormData"></crew>
                </v-tab-item>
                <v-tab-item>
                  <equipment ref="equipmentForm" @updateFormData="updateFormData"></equipment>
                </v-tab-item>
                <v-tab-item>
                  <site-info ref="siteinfoForm" @updateFormData="updateFormData"></site-info>
                </v-tab-item>
                <v-tab-item>
                  <maps ref="mapForm" @updateFormData="updateFormData"></maps>
                </v-tab-item>
                <v-tab-item>
                  <atc ref="atcForm" @updateFormData="updateFormData"></atc>
                </v-tab-item>
                <!-- <v-tab-item>
              <risk-management></risk-management>
            </v-tab-item>
            <v-tab-item>
              <emergency></emergency>
            </v-tab-item> -->
                <v-tab-item>
                  <charts-uploads ref="chartForm" @updateFormData="updateFormData"></charts-uploads>
                </v-tab-item>
              </v-tabs>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogEdit" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card flat>
          <validation-observer ref="observerEditor">
            <form id="submit_mission_form" class="">
              <v-toolbar flat :style="`border-radius: 0px;`">
                <v-btn icon @click="dialogEdit = false">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
                <v-toolbar-title>New Mission</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="update">
                  Save
                </v-btn>
              </v-toolbar>
              <v-divider></v-divider>
              <v-tabs>
                <v-tab>Task</v-tab>
                <v-tab>Crew</v-tab>
                <v-tab>Equipment</v-tab>
                <v-tab>Site Information</v-tab>
                <!-- <v-tab>Maps</v-tab> -->
                <v-tab>ATC</v-tab>
                <!-- <v-tab>Risk Management</v-tab>
            <v-tab>Emergency</v-tab> -->
                <v-tab>Charts & Uploads</v-tab>

                <v-tab-item>
                  <task-edit ref="editTaskForm" :tasks="editor.task" @updateFormData="updateFormData"></task-edit>
                </v-tab-item>
                <v-tab-item>
                  <crew-edit ref="editCrewForm" :crew="editor.crew" @updateFormData="updateFormData"></crew-edit>
                </v-tab-item>
                <v-tab-item>
                  <equipment-edit
                    ref="editEquipmentForm"
                    :equipment="editor.equipment"
                    @updateFormData="updateFormData"
                  ></equipment-edit>
                </v-tab-item>
                <v-tab-item>
                  <site-info-edit
                    ref="editSiteinfoForm"
                    :site_infos="editor.siteinfo"
                    @updateFormData="updateFormData"
                  ></site-info-edit>
                </v-tab-item>
                <!-- <v-tab-item>
                  <maps-edit ref="editMapForm" :weather="editor.weather" @updateFormData="updateFormData"></maps-edit>
                </v-tab-item> -->
                <v-tab-item>
                  <atc-edit ref="editAtcForm" :atc="editor.atc" @updateFormData="updateFormData"></atc-edit>
                </v-tab-item>
                <!-- <v-tab-item>
              <risk-management></risk-management>
            </v-tab-item>
            <v-tab-item>
              <emergency></emergency>
            </v-tab-item> -->
                <v-tab-item>
                  <charts-uploads-edit
                    ref="editChartForm"
                    :media="editor.chart_upload"
                    @updateFormData="updateFormData"
                  ></charts-uploads-edit>
                </v-tab-item>
              </v-tabs>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>
<script>
import { mdiPencil, mdiDelete, mdiClose, mdiExport } from "@mdi/js";
import { ValidationObserver } from "vee-validate";
import Request from "@/core/api/request";
import permission from "@/directive/permission"; // Permission directive (v-permission)
import Atc from "./components/ATC.vue";
import Crew from "./components/Crew.vue";
import Equipment from "./components/Equipment.vue";
import Maps from "./components/Maps.vue";
import SiteInfo from "./components/SiteInfo.vue";
import Task from "./components/Task.vue";
import ChartsUploads from "./components/Uploads.vue";
import AtcEdit from "./components/ATCEdit.vue";
import CrewEdit from "./components/CrewEdit.vue";
import EquipmentEdit from "./components/EquipmentEdit.vue";
import SiteInfoEdit from "./components/SiteInfoEdit.vue";
import TaskEdit from "./components/TaskEdit.vue";
import ChartsUploadsEdit from "./components/UploadsEdit.vue";
import util from "@/core/utils/misc";

export default {
  name: "WeatherBrowse",
  directives: { permission },
  components: {
    ValidationObserver,
    Atc,
    Crew,
    Equipment,
    Maps,
    SiteInfo,
    Task,
    ChartsUploads,
    AtcEdit,
    CrewEdit,
    EquipmentEdit,
    SiteInfoEdit,
    TaskEdit,
    ChartsUploadsEdit,
  },
  data() {
    return {
      loading: true,
      search: "",
      browse: [],
      editor: {},
      headers: [
        { text: "NO.", value: "index", sortable: true },
        { text: "Start", value: "task", sortable: true },
        { text: "LOS", value: "los", sortable: true },
        { text: "Longitude", value: "lon", sortable: false },
        { text: "Latitude", value: "lat", sortable: false },
        { text: "Action.", value: "actions", sortable: false },
      ],
      icons: {
        mdiPencil,
        mdiDelete,
        mdiClose,
        mdiExport,
      },
      dialogAdd: false,
      dialogEdit: false,
    };
  },

  computed: {
    perms() {
      return {
        read: this.$IYA.ACL.PERMISSION_USER_READ,
        add: this.$IYA.ACL.PERMISSION_USER_ADD,
        remove: this.$IYA.ACL.PERMISSION_USER_REMOVE,
      };
    },
    DOMAIN() {
      return this.$DOMAIN;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      util.isLoading({ active: true, title: "Please Wait...." });
      await this.$store.dispatch(`${this.$IYA.NAMESPACE.SYS}/${this.$IYA.ACTION.MISSION_DEPENDENCIES}`);
      const { data, status } = await Request.get(`${this.$IYA.URI.MISSION__BROWSE}`);
      util.isLoading();
      if (status !== this.$IYA.HTTP.OK) {
        this.loading = false;

        return;
      }
      if (data.data.length < 1) {
        this.loading = false;

        return;
      }
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.browse = data.data;
      this.loading = false;
    },

    updateFormData(value) {
      console.log(value);
    },

    read(param) {
      const filt = this.browse.filter(x => x.id === param);
      // eslint-disable-next-line
      this.editor = filt[0];
      this.dialogEdit = true;
      console.log(this.editor);
    },

    add() {
      this.dialogAdd = true;
    },

    report(id) {
      util.isLoading({ active: true, title: "Generating report. Please Wait...." });
      console.log(id);
      util.isLoading();
    },

    trash(id) {
      this.$swal({
        title: `Are you sure?`,
        text: `You can't revert your action`,
        type: `warning`,
        showCancelButton: true,
        confirmButtonText: `Yes Delete it!`,
        cancelButtonText: `No, Keep it!`,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(result => {
        if (result.value) {
          this.destroy(id);
        } else {
          this.$swal(`Cancelled`, `Your records are still intact`, `info`);
        }
      });
    },

    async destroy(id) {
      await Request.destroy(`${this.$IYA.URI.WEATHER__DELETE}`, { id: id }).then(response => {
        if (response.status !== this.$IYA.HTTP.OK) {
          this.$swal(`Failed`, `Failed to remove record!`, `error`);
          return;
        }
        this.$swal(`Deleted`, `You successfully deleted this record`, `success`);
        this.initialize();
      });
    },

    formatData() {
      const task = this.$refs.taskForm.updateFormData();
      const crew = this.$refs.crewForm.updateFormData();
      const equipment = this.$refs.equipmentForm.updateFormData();
      const siteinfo = this.$refs.siteinfoForm.updateFormData();
      const map = this.$refs.mapForm.updateFormData();
      const atc = this.$refs.atcForm.updateFormData();
      const chart = this.$refs.chartForm.updateFormData();

      return {
        ...task,
        ...crew,
        ...equipment,
        ...siteinfo,
        ...map,
        ...atc,
        ...chart,
      };
    },

    editFormatData() {
      const task = this.$refs.editTaskForm.updateFormData();
      const crew = this.$refs.editCrewForm.updateFormData();
      const equipment = this.$refs.editEquipmentForm.updateFormData();
      const siteinfo = this.$refs.editSiteinfoForm.updateFormData();
      const map = this.$refs.editMapForm.updateFormData();
      const atc = this.$refs.editAtcForm.updateFormData();
      const chart = this.$refs.editChartForm.updateFormData();

      return {
        ...task,
        ...crew,
        ...equipment,
        ...siteinfo,
        ...map,
        ...atc,
        ...chart,
      };
    },

    async submit() {
      this.mission = this.formatData();
      this.$refs.observer.validate();
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Please Wait...." });
        console.log(this.mission);
        await Request.post(`${this.$IYA.URI.MISSION__ADD}`, this.mission).then(response => {
          if (response.data.success) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
            this.initialize();
          } else {
            // eslint-disable-next-line
            if (response.data.error) {
              this.$swal(`Failed !`, `${response.data.error}`, `error`);
            } else {
              this.$swal(`Failed !`, `${response.message}`, `error`);
            }
          }
        });
        util.isLoading();
      } else {
        this.$swal(`Validation`, `Make sure all the required fields in each tab are filled`, `info`);
      }
    },

    async update() {
      this.mission = this.editFormatData();
      this.$refs.observerEditor.validate();
      if (this.$refs.observerEditor.validate()) {
        util.isLoading({ active: true, title: "Please Wait...." });
        console.log(this.mission);
        await Request.post(`${this.$IYA.URI.MISSION__EDIT}`, this.mission).then(response => {
          console.log(response);
          if (response.status === this.$IYA.HTTP.OK) {
            this.$swal(`Success`, `Action fulfilled`, `success`);
          }
        });
        util.isLoading();
      } else {
        this.$swal(`Validation`, `Make sure all the required fields in each tab are filled`, `info`);
      }
    },
  },
};
</script>
