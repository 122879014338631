<template>
  <v-card flat>
    <v-card-text>
      <v-container class="ma-10">
        <v-row>
          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="User" rules="required">
              <v-select
                v-model="tasks.user_id"
                :items="dropdown.users"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                menu-props="auto"
                label="Dispatcher"
                outlined
                required
                dense
                hint="Select person"
                persistent-hint
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Client" rules="required">
              <v-select
                v-model="tasks.client_id"
                :items="dropdown.clients"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                menu-props="auto"
                label="Client"
                outlined
                required
                dense
                hint="Select client"
                persistent-hint
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <v-card outlined class="">
              <v-card-title>Job Information</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Type Of Operation" rules="required">
                      <v-text-field
                        v-model="tasks.type_of_operation"
                        :error-messages="errors"
                        label="Type Of Operation"
                        required
                        outlined
                        dense
                        hint="Brief job description"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Location Description" rules="required">
                      <v-text-field
                        v-model="tasks.location_description"
                        :error-messages="errors"
                        label="Location Description"
                        required
                        outlined
                        dense
                        hint="Brief location description"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Start Date" rules="required">
                      <date-picker-dialog
                        v-model="tasks.start_date"
                        :minimum_date="`1950-01-01`"
                        :error-messages="errors"
                        :label="`Start Date`"
                        @forceDateUpdate="forceDateUpdate"
                      ></date-picker-dialog>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Estimated Flight Duration" rules="required">
                      <custom-time-picker
                        v-model="tasks.estimated_flight_duration"
                        required
                        :label="`Estimated Flight Duration`"
                        :error-messages="errors"
                        @forceTimeUpdate="forceTimeUpdate"
                      ></custom-time-picker>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <validation-provider v-slot="{ errors }" name="Line Of Sight Type" rules="required">
                      <v-select
                        v-model="tasks.line_of_sight_type"
                        :items="lineOfSight"
                        :error-messages="errors"
                        menu-props="auto"
                        label="Line Of Sight Type"
                        outlined
                        required
                        dense
                        hint="Select LOS type"
                        persistent-hint
                      ></v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <validation-provider v-slot="{ errors }" name="Client Requirements" rules="required">
                      <v-textarea
                        v-model="tasks.client_requirements"
                        filled
                        auto-grow
                        label="Client Requirements"
                        rows="2"
                        row-height="20"
                        required
                        dense
                        :error-messages="errors"
                        hint="Additional information"
                        persistent-hint
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider } from "vee-validate";
import DatePickerDialog from "@/components/DatePickerDialog.vue";
import CustomTimePicker from "@/components/TimePickerDialog.vue";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "TaskEdit",
  components: {
    ValidationProvider,
    DatePickerDialog,
    CustomTimePicker,
  },
  props: {
    tasks: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return { lineOfSight: ["VLOS", "BVLOS", "RVLOS", "EVLOS"] };
  },
  computed: {
    dropdown() {
      return this.$store.getters.dropdown;
    },
  },
  methods: {
    forceDateUpdate(value) {
      this.tasks.start_date = value;
    },
    updateFormData() {
      return { tasks: this.tasks };
    },
    forceTimeUpdate(value) {
      if (value) {
        this.tasks.estimated_flight_duration = value;
      }
    },
  },
};
</script>
